<template>
  <div
    ref="player"
    class="player"
    :style="{height: height}"
  />
</template>

<script>
import DPlayer from 'dplayer';

export default {
  props: {
    height: {
      type: String,
      default: '100%'
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dplayer: null
    };
  },
  mounted() {
    setTimeout(() => {
      this.dplayer = new DPlayer({
        container: this.$refs.player,
        video: {
          // url: 'http://139.159.190.59:9000/video/tool_remind.mp4',
          url: this.url,
        },
        hotkey: true,
        autoplay: true
      });
    }, 100);
  },
  beforeDestroy() {
    this.dplayer = null;
  },
};
</script>
